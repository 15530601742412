import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

const Depliant = () => {
  const data = useStaticQuery(graphql`
    query DepliantQuery {
      site: strapiDepliant {
        PDF {
          publicURL
        }
        icon {
          publicURL
        }
        texte
        couleur {
          valeur
        }
      }
    }
  `);
  //console.log(data);
  const color = data.site.couleur.valeur;
  const iconStyle = {
    objectFit: "cover",
    width: "50px",
    height: "50px",
    marginRight: "10px",
  };
  const dStyle = {
    border: "5px",
    color: color,
    borderRadius: "5px",
    borderStyle: "solid",
    boxShadow: "1px 3px 2px #888888",
    padding: "0.5rem",
    marginTop: "10px",
    marginBottom: "10px",
  };
  const spanStyle = {
    color: "black",
    fontSize: "1.2em",
  };
  return (
    <div style={dStyle}>
      <a
        href={data.site.PDF.publicURL}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          style={iconStyle}
          src={data.site.icon.publicURL}
          alt={data.site.texte}
        />

        <span style={spanStyle}>{data.site.texte}</span>
      </a>
    </div>
  );
};

export default Depliant;
