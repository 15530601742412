import React from "react";
import PropTypes from "prop-types";
import Markdown from "markdown-to-jsx";

const Card = ({ title, content, color }) => {
  const dStyle = {
    border: "5px",
    borderColor: color,
    borderRadius: "5px",
    borderStyle: "solid",
    padding: "0.7rem",
    marginBottom: "10px",
  };
  const hStyle = {
    fontSize: "1.5rem",
  };
  const cStyle = {
    fontSize: "0.9rem",
    textAlign: "justify",
    color: "black",
  };
  return (
    <div style={dStyle}>
      <h2 style={hStyle}>{title}</h2>
      <span style={cStyle} className="content-class">
        <Markdown>{content}</Markdown>
      </span>
    </div>
  );
};

export default Card;

Card.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  color: PropTypes.string,
};

Card.defaultProps = {
  title: "",
  content: "",
  color: "",
};
