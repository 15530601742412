import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';

import Image from 'gatsby-image';
import urlSlug from 'url-slug';

const coursCardWidth = '350px';

const CoursCards = () => {
  const data = useStaticQuery(graphql`
    query coursDescriptionQuery {
      dessinPeinture: strapiCoursDessinPeinture {
        description_des_ateliers {
          id
          nom
          images {
            id
            image {
              childImageSharp {
                fluid(maxWidth: 600, quality: 100) {
                  ...GatsbyImageSharpFluid
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
          image_presentation {
            image {
              publicURL
              childImageSharp {
                fluid(maxWidth: 500, quality: 100) {
                  ...GatsbyImageSharpFluid
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
            nom
          }
        }
        couleur {
          valeur
        }
        id
      }
      sculptureModelage: strapiCoursDeSculptureModelage {
        description_des_ateliers {
          id
          nom
          images {
            id
            image {
              childImageSharp {
                fluid(maxWidth: 600, quality: 100) {
                  ...GatsbyImageSharpFluid
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
          image_presentation {
            image {
              publicURL
              childImageSharp {
                fluid(maxWidth: 500, quality: 100) {
                  ...GatsbyImageSharpFluid
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
            nom
          }
        }
        couleur {
          valeur
        }
        id
      }
      coursEnfants: strapiCoursEnfants {
        description_des_ateliers {
          id
          nom
          images {
            id
            image {
              childImageSharp {
                fluid(maxWidth: 600, quality: 100) {
                  ...GatsbyImageSharpFluid
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
          image_presentation {
            image {
              publicURL
              childImageSharp {
                fluid(maxWidth: 500, quality: 100) {
                  ...GatsbyImageSharpFluid
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
            nom
          }
        }
        couleur {
          valeur
        }
        id
      }
      stagesDessinModelage: strapiStagesDessinModelage {
        description_des_stages {
          id
          nom
          images {
            id
            image {
              childImageSharp {
                fluid(maxWidth: 600, quality: 100) {
                  ...GatsbyImageSharpFluid
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
          image_presentation {
            image {
              publicURL
              childImageSharp {
                fluid(maxWidth: 500, quality: 100) {
                  ...GatsbyImageSharpFluid
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
            nom
          }
        }
        couleur {
          valeur
        }
        id
      }
      site: site {
        siteMetadata {
          title
          description
          menuLinks {
            name
            link
            items {
              name
              link
              id
            }
          }
        }
      }
    }
  `);

  const { menuLinks } = data.site.siteMetadata;

  const coursLinks = menuLinks.reduce((acc, curr) => {
    if (curr.name === 'Cours et stages') {
      return curr;
    }
    return acc;
  }, null);

  //

  //
  data.stagesDessinModelage.description_des_ateliers = data.stagesDessinModelage.description_des_stages;

  data.stagesDessinModelage.description_des_stages.forEach((item) => {
    if (item.nom.indexOf('dessin') !== -1) {
      data.dessinPeinture.description_des_ateliers.push(item);
    } else if (item.nom.indexOf('sculpture') !== -1) {
      data.sculptureModelage.description_des_ateliers.push(item);
    }
  });

  data.dessinPeinture.description_des_ateliers = [
    ...new Set(data.dessinPeinture.description_des_ateliers),
  ];
  data.sculptureModelage.description_des_ateliers = [
    ...new Set(data.sculptureModelage.description_des_ateliers),
  ];

  const colStyle = {
    color: 'black',
    fontSize: '1rem',
    fontWeight: 'bold',
    width: coursCardWidth,
  };

  return (
    <div className="container">
      <div className="row d-flex justify-content-center">
        <div className="m-2">
          <TocItem
            name="Peindre ou Dessiner"
            color={data.dessinPeinture.couleur.valeur}
            key="ti_1"
          />
          <div className="" style={colStyle}>
            <CoursPanel ateliers={data.dessinPeinture.description_des_ateliers} links={coursLinks} id={data.dessinPeinture.id} stagesId={data.stagesDessinModelage.id} color={data.dessinPeinture.couleur.valeur} key="cp_1" />
          </div>
        </div>
        <div className="m-2" style={colStyle}>
          <TocItem
            name="Sculpter ou modeler l'argile"
            color={data.sculptureModelage.couleur.valeur}
            key="ti_2"
          />
          <div className="">
            <CoursPanel ateliers={data.sculptureModelage.description_des_ateliers} links={coursLinks} id={data.sculptureModelage.id} stagesId={data.stagesDessinModelage.id} color={data.sculptureModelage.couleur.valeur} key="cp_2" />
          </div>
        </div>
        <div className="m-2" style={colStyle}>
          <TocItem
            name="Des cours pour enfants et adolescents adaptés à tous les âges"
            color={data.coursEnfants.couleur.valeur}
            key="ti_3"
          />
          <div className="">
            <CoursPanel
              ateliers={data.coursEnfants.description_des_ateliers}
              links={coursLinks}
              id={data.coursEnfants.id}
              color={data.coursEnfants.couleur.valeur}
              key="cp_3"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const CoursPanel = ({
  ateliers, links, id, stagesId, color,
}) => {
  const link = links.items.reduce((acc, curr) => {
    if (curr.id === id) {
      return curr;
    }
    return acc;
  }, null);
  const stageLink = links.items.reduce((acc, curr) => {
    if (curr.id === stagesId) {
      return curr;
    }
    return acc;
  }, null);
  const SeancesLinks = ateliers.map((atelier) => {
    if (atelier.nom.indexOf('Stages') === -1 || !stageLink) {
      return {
        nom: atelier.nom,
        id: atelier.id,
        image: atelier.image_presentation.image,
        link: link.link,
        color,
      };
    }
    return {
      nom: atelier.nom,
      id: atelier.id,
      image: atelier.image_presentation.image,
      link: stageLink.link,
      color,
    };
  });
  return SeancesLinks.map((atelier, index) => (<div key={`cc_${index}`}><CoursCard nom={atelier.nom} id={atelier.id} image={atelier.image} link={atelier.link} color={color} /></div>));
};

const TocItem = ({ name, color }) => {
  const spanStyle = {
    color: 'black',
    fontWeight: 'bold',
  };

  const tStyle = {
    height: '6rem',
    backgroundColor: color,
    borderRadius: '5px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '3px',
  };
  return (
    <div style={tStyle}>
      <h4 style={spanStyle}>{name}</h4>
    </div>
  );
};

const CoursCard = ({
  nom, id, image, link, color,
}) => {
  const cStyle = {
    objectFit: 'cover',
    width: coursCardWidth,
    height: '260px',
  };
  const dStyle = {
    boxShadow: '1px 3px 2px #888888',
    transition: '0.3s',
    margin: '1px',
    borderRadius: '5px',
  };
  const pStyle = {
    color: 'black',
    fontWeight: 'bold',
    backgroundColor: color,
    padding: '5px',
  };

  const hStyle = {
    fontWeight: 'bold',
  };
  // we take the first image:
  const imageCours = image;
  const anchorLink = `${link.slice(0, -1)}#${urlSlug(nom)}`;
  return (
    <Link to={anchorLink} style={dStyle} key={`ka_${id}`}>
      <Image
        style={cStyle}
        className="card-img"
        fluid={imageCours.childImageSharp.fluid}
        alt={nom}
      />
      <div style={pStyle}>
        <h5 style={hStyle}>{nom}</h5>
      </div>
    </Link>
  );
};

export default CoursCards;
