import PropTypes from 'prop-types';
import React from 'react';
import Image from 'gatsby-image';
import blank from '../../images/blank.png';

const pStyle = {
  boxShadow: '1px 3px 2px #888888',
  width: '100%',
};


const PictureOfTheDay = ({
  title, description, url, img,
}) => (
  <>
    <Image style={pStyle} title={title} alt={`${title} - ${description}`} fluid={img.childImageSharp.fluid} className="rounded" />
  </>
);

PictureOfTheDay.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
  imgSrc: PropTypes.string,
};

PictureOfTheDay.defaultProps = {
  title: '',
  description: '',
  url: '',
  imgSrc: '',
};

export default PictureOfTheDay;
