import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import Image from "gatsby-image";
import Markdown from "markdown-to-jsx";
import TOC from "../page/toc";
import Depliant from "../page/depliant";
import Card from "../page/card";
import PictureOfTheDay from "../pictureOfTheDay";
import CoursDescriptions from "./coursDescriptionCards";
import HomepageNews from "./HomepageNews";

const Headings = ({ cards, address, contact, description, image_du_jour }) => {
  const logoStyle = {
    objectFit: "cover",
    width: "100%",
    height: "auto",
  };
  const hStyle = {
    fontSize: "1.8rem",
    fontWeight: "bold",
  };
  const dStyle = {
    border: "5px",
    borderColor: description.couleur.valeur,
    borderRadius: "5px",
    borderStyle: "solid",
    padding: "0.7rem",
    marginBottom: "10px",
    backgroundColor: description.couleur.valeur,
  };

  const cStyle = {
    fontSize: "1rem",
    textAlign: "justify",
    color: "black",
  };
  return (
    <>
      <div className="row mt-3 ">
        <div className="p-3">
          <div style={dStyle}>
            <h2 style={hStyle}>{description.title}</h2>
            <span style={cStyle} className="content-class">
              <Markdown>{description.contenu}</Markdown>
            </span>
          </div>
        </div>
      </div>

      <CoursDescriptions />
      <HomepageNews color={address.couleur.valeur} />
    </>
  );
};

const PageCard = ({ title, url, color, imgSrc, iconSrc, anchors }) => {
  // console.log(iconSrc);

  const dStyle = {
    borderColor: color,
    border: "5px",
    marginBottom: "10px",
  };
  const imgStyle = {
    objectFit: "cover",
    width: "auto",
    height: "spx",
    border: "5px",
    color,
    borderRadius: "5px",
    borderStyle: "solid",
  };
  const iconStyle = {
    objectFit: "cover",
    width: "auto",
    height: "60px",
  };

  return (
    <div className="row">
      <Link to={url}>
        <div style={dStyle} className="card">
          <Image
            style={imgStyle}
            fluid={imgSrc.childImageSharp.fluid}
            title={title}
            alt={title}
          
          />
        </div>
        <h2 className="text-invisible">{title}</h2>
      </Link>
      <div className="d-flex align-top justify-content-start ">
        <div style={{ width: "60px", height: "auto" }}>
          <div className="my-auto mx-auto text-center">
            <Link to={url}>
              <Image
                style={iconStyle}
                fluid={iconSrc.childImageSharp.fluid}
                title={title}
                alt={title}
              />
            </Link>
          </div>
        </div>
        <div className="pl-2 my-auto text-left">
          {anchors.map((anchor, index) => (
            <ShortCuts
              name={anchor.name}
              url={`${url}#${anchor.key}`}
              color={color}
              key={`${anchor.key}${index}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const ShortCuts = ({ name, url, color }) => {
  const btnStyle = {
    backgroundColor: color,
    width: "10px",
    height: "10px",
  };
  const spanStyle = {
    fontSize: "0.8rem",
    color: "black",
  };
  return (
    <div className="d-flex justify-content-start mb-1 mt-0 align-items-center">
      <div className="btn p-1" style={btnStyle} />
      <div className=" card-text pl-1 ">
        <h3 style={spanStyle} className=" card-text pl-1 ">
          <Link to={url}>
            <span style={spanStyle}>{name}</span>
          </Link>
        </h3>
      </div>
    </div>
  );
};
const HomeCardPanel = ({ cards }) => (
  <div className="row">
    {cards.map((card) => (
      <PageCard
        key={card.id}
        title={card.name}
        url={card.link}
        imgSrc={card.image_presentation}
        iconSrc={card.icon}
        color={card.couleur.valeur}
        anchors={card.anchors}
      />
    ))}
  </div>
);

export default Headings;

Headings.propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      nom: PropTypes.string,
      name: PropTypes.string,
      link: PropTypes.string,
      image_presentation: PropTypes.shape({
        publicURL: PropTypes.string,
      }),
      icon: PropTypes.shape({
        publicURL: PropTypes.string,
      }),
      couleur: PropTypes.object,
      anchors: PropTypes.arrayOf(PropTypes.object),
    })
  ),
  adress: PropTypes.shape({
    contenu: PropTypes.string,
    nom: PropTypes.string,
    id: PropTypes.string,
  }),
  contact: PropTypes.object,
  description: PropTypes.object,
};

Headings.defaultProps = {
  cards: [],
  adresse: PropTypes.shape({
    contenu: "",
    nom: "",
    id: "",
  }),
  contact: {},
  description: {},
};
