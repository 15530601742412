import React from 'react';
import { graphql } from 'gatsby';

import Home from '../templates/homepage';

export default function Template({ data, pageContext }) {
  // console.log("page context : ", pageContext);
  return <Home data={data} pageContext={pageContext} />;
}

export const pageQuery = graphql`
  query indexQuery {
    site: site {
      siteMetadata {
        title
        description
        menuLinks {
          name
          link
          items {
            name
            link
            id
          }
        }
      }
    }
    page: strapiHomepage {
      id
      couleur {
        valeur
      }
      adresse {
        contenu
        nom
        id
        couleur {
          valeur
        }
      }
      contact {
        contenu
        id
        nom
        couleur {
          valeur
        }
      }
      nom
      en_quelques_mots {
        contenu
        id
        nom
        couleur {
          valeur
        }
      }
      image_du_jour {
        description
        titre
        image {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
      description
      meta {
        description
        title
        url
        image {
          publicURL
          childImageSharp {
            fixed(width: 200) {
              width
              height
              src
            }
          }
        }
      }
    }
    modelage: strapiCoursDeSculptureModelage {
      nom
      id
      image_presentation {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      icon {
        publicURL
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      couleur {
        valeur
      }
    }
    dessin: strapiCoursDessinPeinture {
      nom
      id
      image_presentation {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      icon {
        publicURL
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      couleur {
        valeur
      }
    }
    coursEnfants: strapiCoursEnfants {
      nom
      id
      image_presentation {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      icon {
        publicURL
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      couleur {
        valeur
      }
    }
    stagesAdultes: strapiStagesDessinModelage {
      nom
      id
      image_presentation {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      icon {
        publicURL
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      couleur {
        valeur
      }
    }
  }
`;
