import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';

import ReactHtmlParser from 'react-html-parser';
import Markdown from 'markdown-to-jsx';
import PictureOfTheDay from '../pictureOfTheDay';

import Posts from '../page/postComponent/posts';

const HomepageNews = ({ color, img }) => {
  const dStyle = {
    backgroundColor: 'blue',
    padding: '10px',
    margin: '5px',
    borderRadius: '5px',
  };
  const nStyle = {};
  const data = useStaticQuery(graphql`
    query Depliant2Query {
      page: allStrapiActualites(filter: { actif: { eq: true } }) {
        nodes {
          contenu
          createdAt
          date_publication
          id
          nom
        }
      }
    }
  `);
  const paragraphStyle = {};
  const news = data.page.nodes;
  // console.log("news : ", news);
  if (news == undefined || news == null || news.length == 0) {
    return <></>;
  }
  return (
    <div style={nStyle} className="row">
      <div style={paragraphStyle} className="col-lg-12">
        <Posts color={color} news={news} />

      </div>
    </div>
  );
};

export default HomepageNews;
