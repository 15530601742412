import React, { Component } from 'react';

import Post from './post';

const Posts = ({ news, color }) => (
  <div id="postPanel" className="container">
    <h2 className="pageCard ">Actualités</h2>
    {news.map((item) => (
      <div key={item.id}>
        <Post
          id={item.id}
          label={item.nom}
          contenu={item.contenu}
          color={color}
        />
      </div>

    ))}

  </div>
);

export default Posts;
