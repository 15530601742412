import React, { Component } from 'react';
import Markdown from 'markdown-to-jsx';
import PropTypes from 'prop-types';

const Post = ({
  label, id, contenu, color, key,
}) => {
  const spanStyle = {
    color: 'black',
    fontSize: '1.2rem',
    WebkitTextDecoration: `${color} solid underline`,
    textDecoration: `${color} solid underline`,
    WebkitTextUnderlinePosition: 'under',
    textUnderlinePosition: 'under',
    fontWeight: 'bold',
    padding: '5px',
    backgroundColor: `${color}`,
    borderColor: `${color}`,

  };

  const cStyle = {
    border: '3px',
    borderStyle: 'solid',
    borderColor: `${color}`,
    boxShadow: '1px 3px 2px #888888',
  };

  return (
    <div className="card mb-3 box-shadow " style={cStyle}>
      <div className="card-title" style={spanStyle}>{label}</div>
      <div id={id} className="collapse card-text m-2" aria-expanded={false}>
        <Markdown>{contenu}</Markdown>
      </div>
      <p className="mt-2 text-right">
        <a role="button" className="collapsed border rounded p-1 m-2" data-toggle="collapse" href={`#${id}`} aria-expanded="false" aria-controls={key} />
      </p>
    </div>
  );
};

export default Post;
