import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';

const Carousel = ({
  cards, address, contact, description,
}) => {
  const logoStyle = {
    objectFit: 'cover',
    width: '100%',
    height: 'auto',
  };
  const hStyle = {
    fontSize: '1.6rem',
    fontWeight: 'bold',
  };
  return (
    <>
      <div
        id="carouselExampleInterval"
        className=" row carousel slide carousel-fade"
        data-ride="carousel"
        data-wrap="true"
        data-interval="1000"
      >
        <div className="carousel-inner">
          {cards.map((card, index) => (
            <PageCard
              key={card.id}
              index={index}
              title={card.name}
              url={card.link}
              imgSrc={card.image_presentation}
              iconSrc={card.icon}
              color={card.couleur.valeur}
              anchors={card.anchors}
            />
          ))}
        </div>
        <a
          className="carousel-control-prev"
          href="#carouselExampleInterval"
          role="button"
          data-slide="prev"
        />
        <a
          className="carousel-control-next"
          href="#carouselExampleInterval"
          role="button"
          data-slide="next"
        />
      </div>
    </>
  );
};

const PageCard = ({
  title, url, color, imgSrc, index,
}) => {
  const dStyle = {
    borderColor: color,
  };
  const imgStyle = {
   // objectFit: 'cover',
    width: '100%',
    height: '330px',
    borderRadius: '5px',
    maxWidth: '100%',
  };

  return (
    <div
      className={` carousel-item ${index === 0 ? 'active' : ''}`}
      data-interval="5000"
    >
      <Link to={url}>
        <Image
          style={imgStyle}
          fluid={imgSrc.childImageSharp.fluid}
          title={title}
          alt={title}
          
        />

        <h2 className="text-invisible">{title}</h2>
      </Link>
    </div>
  );
};

const ShortCuts = ({ name, url, color }) => {
  const btnStyle = {
    backgroundColor: color,
    width: '10px',
    height: '10px',
  };
  const spanStyle = {
    fontSize: '0.8rem',
    color: 'black',
  };
  return (
    <div className="d-flex justify-content-start mb-1 mt-0 align-items-center">
      <div className="btn p-1" style={btnStyle} />
      <div className=" card-text pl-1 ">
        <h3 style={spanStyle} className=" card-text pl-1 ">
          <Link to={url}>
            <span style={spanStyle}>{name}</span>
          </Link>
        </h3>
      </div>
    </div>
  );
};
const HomeCardPanel = ({ cards }) => (
  <div className="row">
    {cards.map((card) => (
      <PageCard
        key={card.id}
        title={card.name}
        url={card.link}
        imgSrc={card.image_presentation}
        iconSrc={card.icon}
        color={card.couleur.valeur}
        anchors={card.anchors}
      />
    ))}
  </div>
);

export default Carousel;

Carousel.propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      nom: PropTypes.string,
      name: PropTypes.string,
      link: PropTypes.string,
      image_presentation: PropTypes.shape({
        publicURL: PropTypes.string,
      }),
      icon: PropTypes.shape({
        publicURL: PropTypes.string,
      }),
      couleur: PropTypes.object,
      anchors: PropTypes.arrayOf(PropTypes.object),
    }),
  ),
  adress: PropTypes.shape({
    contenu: PropTypes.string,
    nom: PropTypes.string,
    id: PropTypes.string,
  }),
  contact: PropTypes.object,
  description: PropTypes.object,
};

Carousel.defaultProps = {
  cards: [],
  adresse: PropTypes.shape({
    contenu: '',
    nom: '',
    id: '',
  }),
  contact: {},
  description: {},
};
