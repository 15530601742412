import React from 'react';
import SEO from '../components/seo';
import Layout from '../components/layout';
import HomepageHeadings from '../components/paragraphs/HomepageHeadings';
import Carousel from '../components/paragraphs/HomepageCarousel';
import Card from '../components/page/card';
import CardPlain from '../components/page/cardPlain';
import PictureOfTheDay from '../components/pictureOfTheDay';
import Depliant from '../components/page/depliant';
import HomepageNews from '../components/paragraphs/HomepageNews';

const Home = ({ data, pageContext }) => {
  const { site } = data;
  const { page } = data; // data.markdownRemark holds your post data

  const categoryLinks = site.siteMetadata.menuLinks.filter((elt) => elt.items !== null)[0].items;
  const contents = [
    data.dessin,
    data.modelage,
    data.coursEnfants,
    data.stagesAdultes,
  ];

  const cards = prepareDataForCardPanel(categoryLinks, contents);

  const description = {};
  description.couleur = { valeur: page.en_quelques_mots.couleur.valeur };
  description.contenu = page.en_quelques_mots.contenu;
  description.title = page.en_quelques_mots.nom;
  return (
    <Layout
      title={`${site.siteMetadata.title} - ${page.nom}`}
      buildTime={pageContext.buildTime}
    >
      <SEO meta={page.meta} />
      <Carousel cards={cards} />
      <HomepageHeadings
        cards={cards}
        address={page.adresse}
        contact={page.contact}
        description={description}
        image_du_jour={page.image_du_jour}
      />
      <div className="row">
        <div className="col-sm-6 p-3">
          <CardPlain
            title={page.contact.nom}
            content={page.contact.contenu}
            color={page.contact.couleur.valeur}
          />
          <CardPlain
            title={page.adresse.nom}
            content={page.adresse.contenu}
            color={page.adresse.couleur.valeur}
          />
          <Depliant />

        </div>
        <div className=" col-sm-6 p-3 float-right ">
          <PictureOfTheDay
            title={page.image_du_jour.titre}
            description={page.image_du_jour.description}
            img={page.image_du_jour.image}

          />
        </div>

      </div>

    </Layout>
  );
};

const prepareDataForCardPanel = (categoryLinks, contents) => {
  const cards = categoryLinks.map((cat) => {
    const catID = cat.id;
    const content = contents.filter((elt) => elt.id === catID)[0];
    const contentKeys = Object.keys(content);
    contentKeys.forEach((key) => {
      cat[key] = content[key];
    });
    // on rajoute deux ancres à la main ( a revoir à terme)
    if (
      catID == 'Cours-de-sculpture-modelage_5eb52f6145fc5253cf28fc86'
      || catID == 'Cours-dessin-peinture_5e99823f0268b24df1621ae0'
    ) {
      cat.anchors = [
        {
          name: 'Horaires et tarifs',
          key: 'Horaires_et_Tarifs',
        },
        {
          name: 'Description des ateliers',
          key: 'description_des_ateliers',
        },
      ];
    } else if (catID == 'Cours-enfants_5e99abdce29ff35838fca232') {
      cat.anchors = [
        {
          name: 'Horaires et tarifs',
          key: 'Horaires_et_Tarifs',
        },
        {
          name: 'Description des ateliers',
          key: 'description_des_ateliers',
        },
        {
          name: 'Stages Vacances Scolaires',
          key: 'stages_vacances_scolaires',
        },
      ];
    } else if (catID == 'Stages-dessin-modelage_5e99b50a5ba92e5e5bb79ee0') {
      cat.anchors = [
        {
          name: 'Calendrier',
          key: 'calendrier',
        },
        {
          name: 'Description des stages',
          key: 'description_des_stages',
        },
      ];
    }
    return cat;
  });
  return cards;
};

export default Home;
